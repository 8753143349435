export interface LogoProps {
  readonly size: number;
  readonly fill?: string;
}

export function RemoLogo(props: LogoProps): JSX.Element {
  return (
    <svg
      style={{ width: props.size, height: props.size }}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Remo Logo</title>
      <path
        d="M59.9714 24.8C59.9714 24.8 57.7714 22.5143 57.3429 21.5143C56.9143 20.5143 56.9429 19.0286 56.9143 17.5143C56.8857 14.7429 56.8286 11.5714 54.6286 9.37143C52.4286 7.17143 49.2571 7.11429 46.4857 7.08571C44.9714 7.05714 43.4286 7.02857 42.4857 6.65714C41.5429 6.28571 40.3143 5.11429 39.2 4.02857C37.2286 2.14286 34.9714 0 32 0C29.0286 0 26.7714 2.14286 24.8 4.02857C23.6857 5.11429 22.5143 6.22857 21.5143 6.65714C20.5143 7.08571 19.0286 7.05714 17.5143 7.08571C14.7429 7.11429 11.5714 7.17143 9.37143 9.37143C7.17143 11.5714 7.11429 14.7429 7.08571 17.5143C7.05714 19.0286 7.02857 20.5714 6.65714 21.5143C6.28571 22.4571 5.11429 23.6857 4.02857 24.8C2.14286 26.7714 0 29.0286 0 32C0 34.9714 2.14286 37.2286 4.02857 39.2C5.11429 40.3143 6.22857 41.4857 6.65714 42.4857C7.08571 43.4857 7.05714 44.9714 7.08571 46.4857C7.11429 49.2571 7.17143 52.4286 9.37143 54.6286C11.5714 56.8286 14.7429 56.8857 17.5143 56.9143C19.0286 56.9429 20.5714 56.9714 21.5143 57.3429C22.4571 57.7143 23.6857 58.8857 24.8 59.9714C26.7714 61.8571 29.0286 64 32 64C34.9714 64 37.2286 61.8571 39.2 59.9714C40.3143 58.8857 41.4857 57.7714 42.4857 57.3429C43.4857 56.9143 44.9714 56.9429 46.4857 56.9143C49.2571 56.8857 52.4286 56.8286 54.6286 54.6286C56.8286 52.4286 56.8857 49.2571 56.9143 46.4857C56.9429 44.9714 56.9714 43.4286 57.3429 42.4857C57.7143 41.5429 58.8857 40.3143 59.9714 39.2C61.8571 37.2286 64 34.9714 64 32C64 29.0286 59.9714 24.8 59.9714 24.8Z"
        fill="#2D5F62"
      />
      <path
        d="M35.36 31.9239C38.2206 31.1025 39.864 29.0031 39.864 26.2953C39.864 22.7051 37.4598 20.6667 32.9253 20.6667C31.4341 20.6667 29.5473 20.8188 27.2953 20.8188H23.9173C22.6391 20.8188 22 21.1535 22 21.9141C22 22.9181 23.3695 22.7964 23.7955 23.4049C24.039 23.7396 24.252 26.5387 24.252 31.7718C24.252 37.3092 24.1607 40.1995 23.6434 40.5951C23.0651 41.0514 22 40.9906 22 41.9338C22 42.9378 23.2782 43.1508 26.1388 43.1508C28.9995 43.1508 30.3081 42.9682 30.3081 41.9338C30.3081 40.9602 29.243 41.0819 28.6648 40.6255C28.1778 40.2604 28.1474 38.3132 28.1474 34.6926V34.0841C28.1474 33.0801 28.1778 32.8063 28.7561 32.8063H30.156C33.747 32.8063 34.4774 34.6013 35.6339 38.5262C36.5773 41.7512 37.0033 43.3333 40.1075 43.3333C42.1769 43.3333 43.3333 42.6031 43.3333 41.6904C43.3333 41.2644 42.9377 40.8993 42.5725 40.8993C42.1465 40.8993 41.8117 40.9602 41.5682 40.9602C40.3814 40.9602 40.3814 39.5302 39.651 37.0049C38.7989 34.145 37.8859 32.6237 35.36 32.1673V31.9239ZM28.2083 30.7069V27.8165C28.2083 25.4738 28.2387 23.983 28.4213 23.3441C28.6343 22.7051 29.2126 22.5834 30.6733 22.5834C33.9296 22.5834 35.6034 23.983 35.6034 26.6908C35.6034 29.5812 33.9601 30.7982 30.5212 30.7982C29.7908 30.7982 29.03 30.7678 28.2083 30.7069Z"
        fill="white"
      />
    </svg>
  );
}
